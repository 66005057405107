var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from "@mui/material";
import { Enums } from "../../../enums";
import { MARGIN_BOTTOM } from "../const";
function InputField(props) {
    return (_jsx("div", __assign({ style: MARGIN_BOTTOM }, { children: _jsx(TextField, { id: "outlined-basic", label: props.label, variant: Enums.DEFAULTVAR, required: props.required, fullWidth: true, onChange: function (e) { return props.onChange(e.target.value); }, value: props.value }) })));
}
export default InputField;
