var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { basicFormJson } from "./basicFormJson";
import Form from "./components/Form";
import formTheme from "./formTheme";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import fontColorContrast from "font-color-contrast";
import i18n from "i18next";
import { DEFAULT_COLOR, GET_TRAINER_URL } from "./const";
function Main(_a) {
    var token = _a.token, lang = _a.lang;
    var formData = basicFormJson;
    var _b = useState({
        name: "",
        email: "",
        company: "",
        logo: "",
        picture: "",
        primaryColor: DEFAULT_COLOR,
        vat: "",
        locale: "",
        userApp: "",
        terms: "",
    }), trainer = _b[0], setTrainer = _b[1];
    var textColor = fontColorContrast(trainer.primaryColor || DEFAULT_COLOR);
    var authHeader = function () { return ({ headers: { Authorization: token || "" } }); };
    useEffect(function () {
        axios.get(GET_TRAINER_URL, authHeader()).then(function (_a) {
            var data = _a.data;
            i18n.changeLanguage(lang ? lang : data.locale);
            setTrainer(__assign(__assign({}, trainer), { name: data === null || data === void 0 ? void 0 : data.name, email: data === null || data === void 0 ? void 0 : data.email, company: data.company, logo: data === null || data === void 0 ? void 0 : data.logo, picture: data === null || data === void 0 ? void 0 : data.picture, primaryColor: (data === null || data === void 0 ? void 0 : data.primaryColor) || DEFAULT_COLOR, vat: data === null || data === void 0 ? void 0 : data.vat, locale: (data === null || data === void 0 ? void 0 : data.locale) || lang || "en_US", userApp: data === null || data === void 0 ? void 0 : data.userApp, terms: data === null || data === void 0 ? void 0 : data.terms }));
        });
    }, []);
    return (_jsx(ThemeProvider, __assign({ theme: formTheme((trainer === null || trainer === void 0 ? void 0 : trainer.primaryColor) || DEFAULT_COLOR, textColor) }, { children: _jsx(Form, { data: formData, b: token, textColor: textColor }) })));
}
export default Main;
