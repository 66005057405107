import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as en_GB from "../translations/form.en_GB.json";
import * as en_US from "../translations/form.en_US.json";
import * as da_DK from "../translations/form.dk_DK.json";
import * as de_DE from "../translations/form.de_DE.json";
import * as nl_NL from "../translations/form.nl_NL.json";
import * as sv_SE from "../translations/form.sv_SE.json";
import * as fi_FI from "../translations/form.fi_FI.json";
import * as nb_NO from "../translations/form.nb_NO.json";
var resources = {
    en_US: en_US,
    en_GB: en_GB,
    da_DK: da_DK,
    de_DE: de_DE,
    nl_NL: nl_NL,
    sv_SE: sv_SE,
    fi_FI: fi_FI,
    nb_NO: nb_NO,
};
export default function initTranslations() {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
        resources: resources,
        lng: "en_US",
        // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
}
