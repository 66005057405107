import { createTheme } from "@mui/material";
import { DEFAULT_COLOR } from "./const";
var formTheme = function (color, textColor) {
    return createTheme({
        palette: {
            primary: {
                main: "".concat(color, "!important"),
            },
            text: {
                primary: DEFAULT_COLOR,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        padding: "4px !important",
                        borderColor: "".concat(color, "!important"),
                        borderRadius: "5px!important",
                        borderStyle: "solid !important",
                        borderWidth: "1px 1px 1px 1px !important",
                        "&:hover": {
                            main: "".concat(color, "!important"),
                        },
                        "&:focus": {
                            backgroundColor: "".concat(textColor, "!important"),
                            color: "".concat(color, "!important"),
                            outline: "".concat(color, "!important"),
                        },
                    },
                    containedPrimary: {
                        backgroundColor: "".concat(color, "!important"),
                        color: "".concat(textColor, "!important"),
                    },
                    outlinedPrimary: {
                        "&:hover": {
                            backgroundColor: "".concat(color, "!important"),
                            color: "".concat(textColor, "!important"),
                        },
                    },
                },
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#eee!important",
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        input: {
                            borderWidth: 0,
                        },
                        padding: "8px",
                    },
                },
            },
        },
    });
};
export default formTheme;
