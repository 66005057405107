import { Enums } from "./enums";
export var basicFormJson = {
    steps: [
        {
            id: "step-1",
            header: "formSteps.step1.goal",
            type: Enums.ANSWERBUTTON,
            options: [
                {
                    id: "btn1",
                    text: "formSteps.step1.weightLoss",
                },
                {
                    id: "btn2",
                    text: "formSteps.step1.healthier",
                },
                {
                    id: "btn3",
                    text: "formSteps.step1.muscle",
                },
                {
                    id: "btn4",
                    text: "formSteps.step1.athlete",
                },
            ],
        },
        {
            id: "step-2",
            header: "formSteps.step2.gender",
            type: Enums.ANSWERBUTTON,
            options: [
                {
                    id: "btn5",
                    text: "formSteps.step2.woman",
                },
                {
                    id: "btn6",
                    text: "formSteps.step2.man",
                },
                {
                    id: "btn16",
                    text: "formSteps.step2.diverse",
                },
            ],
        },
        {
            id: "step-3",
            header: "formSteps.step3.age",
            type: Enums.ANSWERBUTTON,
            options: [
                {
                    id: "btn7",
                    text: "formSteps.step3.under",
                },
                {
                    id: "btn8",
                    text: "formSteps.step3.between1",
                },
                {
                    id: "btn9",
                    text: "formSteps.step3.between2",
                },
                {
                    id: "btn10",
                    text: "formSteps.step3.over",
                },
            ],
        },
        {
            id: "step-4",
            header: "formSteps.step4.aboutYou",
            type: Enums.COMMENT,
            label: "formSteps.step4.attention",
        },
        {
            id: "step-5",
            header: "formSteps.step5.contactTime",
            type: Enums.ANSWERBUTTON,
            options: [
                {
                    id: "btn11",
                    text: "formSteps.step5.9-12",
                },
                {
                    id: "btn12",
                    text: "formSteps.step5.12-16",
                },
                {
                    id: "btn13",
                    text: "formSteps.step5.16-18",
                },
                {
                    id: "btn14",
                    text: "formSteps.step5.18-21",
                },
            ],
        },
    ],
};
