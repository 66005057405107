var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
// Responsive Fontsize 
var StyledHeader = styled(Typography)(function (props) {
    var _a;
    if (props.variant == "h4") {
        return _a = {},
            // mui is acting weird and doesn't believe the type for breakpoints exists lol 10hi f9z e9
            // @ts-ignore
            _a[props.theme.breakpoints.down("sm")] = {
                fontSize: "1.5rem",
            },
            _a;
    }
    return null;
});
function Header(props) {
    return (_jsx(Stack, __assign({ m: 2 }, { children: _jsx(StyledHeader, __assign({ variant: "h4" }, { children: props.text })) })));
}
export default Header;
