var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
function LinearProgressBar(props) {
    return (_jsx(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: _jsx(Box, __assign({ sx: { width: "100%", mr: 1 } }, { children: _jsx(LinearProgress, __assign({ variant: "determinate", style: { height: 10 } }, props)) })) })));
}
export default function ProgressBar(props) {
    var _a = React.useState(0), progress = _a[0], setProgress = _a[1];
    useEffect(function () {
        setProgress(props.percent);
    }, [props.percent]);
    return (_jsx(Box, __assign({ sx: { width: "100%" } }, { children: _jsx(LinearProgressBar, { value: progress }) })));
}
