import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import initTranslations from "./i18n";
var rootDiv = document.getElementById("zenfitForm");
var root = createRoot(rootDiv);
var token = rootDiv.getAttribute("data-t") || undefined;
var lang = rootDiv.getAttribute("lang") || undefined;
initTranslations();
root.render(_jsx(Main, { token: token, lang: lang }));
