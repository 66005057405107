var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { Enums } from "../../../enums";
function AnswerButtonGroup(props) {
    return (_jsx(Button, __assign({ variant: props.active ? Enums.PRESSEDBUTTONVAR : Enums.DEFAULTVAR, size: Enums.BUTTONSIZE, onClick: props.onClick, style: { width: "100%" } }, { children: props.text })));
}
export default AnswerButtonGroup;
