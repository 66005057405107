export var Enums;
(function (Enums) {
    // FONTS
    Enums["FONT_PRIMARY"] = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  sans-serif";
    // BUTTONS
    Enums["ANSWERBUTTON"] = "answerBtn";
    Enums["BUTTONSIZE"] = "large";
    Enums["PRESSEDBUTTONVAR"] = "contained";
    Enums["DEFAULTVAR"] = "outlined";
    //
    // OTHER
    Enums["INPUT"] = "input";
    Enums["COMMENT"] = "comment";
    Enums["NAME"] = "name";
    Enums["PHONE"] = "phone";
    Enums["EMAIL"] = "email";
    Enums["INSTAGRAM"] = "instagram";
    Enums["C_FORM"] = "CONTACT_FORM";
})(Enums || (Enums = {}));
