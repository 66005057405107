import * as Yup from "yup";
import i18next from "i18next";
// FIXED VALUES
export var CONTROL_BUTTON_STYLE = { width: "100%" };
export var MARGIN_BOTTOM = { marginBottom: 10 };
export var INITIAL_CONTACT_INFO = { name: "", phone: "", email: "", instagram: "" };
// VALIDATION
export var CONTACT_INFO_VALIDATION = function () {
    var t = function (string) { return i18next.t(string); };
    return Yup.object().shape({
        name: Yup.string().required(t("validation.name")),
        phone: Yup.string().required(t("validation.phone.required")),
        email: Yup.string()
            .email(t("validation.email.invalid"))
            .required(t("validation.email.required")),
        instagram: Yup.string(),
    });
};
