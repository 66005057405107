var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import { CONTROL_BUTTON_STYLE } from "./const";
import InputField from "./InputField";
import { useTranslation } from "react-i18next";
import CommentField from "./CommentField";
import { Enums } from "../../enums";
import AnswerButtonGroup from "./AnswerButtonGroup";
import Header from "./Header";
import axios from "axios";
import ContactInfo from "./ContactInfo";
import ProgressBar from "./ProgressBar";
import SuccessPage from "./SuccessPage";
import { POST_LEAD_URL } from "../../const";
function Form(_a) {
    var data = _a.data, b = _a.b, textColor = _a.textColor;
    var t = useTranslation().t;
    var unAnsweredState = function (data) {
        return data.steps.reduce(function (finalResult, step) {
            if (step.type === Enums.INPUT) {
                step.options.forEach(function (option) {
                    return (finalResult["".concat("- " + t(step.header), " _ ").concat(t(option.text))] = "");
                });
            }
            else {
                finalResult["- " + t(step.header)] = "";
            }
            return finalResult;
        }, {});
    };
    var startPercent = (1 / (data.steps.length + 1)) * 100;
    var _b = useState(null), state = _b[0], setState = _b[1];
    var _c = useState(startPercent), progressPercent = _c[0], setProgressPercent = _c[1];
    var _d = useState(false), last = _d[0], setLast = _d[1];
    var _e = useState(""), contactID = _e[0], setContactID = _e[1];
    var _f = useState(false), formSent = _f[0], setFormSent = _f[1];
    var _g = useState(0), i = _g[0], setI = _g[1];
    var step = data.steps[i];
    var stepID = "- " + t(step.header);
    var updateState = function (key, value) {
        var _a;
        return setState(__assign(__assign({}, state), (_a = {}, _a[key] = value, _a)));
    };
    useEffect(function () {
        last
            ? setProgressPercent(100 - startPercent)
            : setProgressPercent((i / (data.steps.length + 1)) * 100);
    }, [i, last]);
    useEffect(function () {
        setState(unAnsweredState(data));
    }, [t]);
    var handleBack = function () { return (last ? setLast(false) : setI(i - 1)); };
    var handleContinue = function () {
        return i + 1 === data.steps.length ? setLast(true) : setI(i + 1);
    };
    var authHeader = function () { return ({ headers: { Authorization: b || "" } }); };
    var handleSend = function (contactFormData) {
        setContactID(Enums.C_FORM);
        axios
            .post(POST_LEAD_URL, {
            name: contactFormData.name,
            email: contactFormData.email,
            phone: contactFormData.phone,
            dialog: JSON.stringify(__assign(__assign({}, state), { instagram: contactFormData.instagram }))
                .replaceAll(",", "\n")
                .replaceAll("{", "")
                .replaceAll('"', " ")
                .replaceAll("}", "")
                .replaceAll(" :", ":"),
            contactTime: "", // TODO ContactTime is a value between 0-4 in zenfit app
        }, authHeader())
            .then(function () { return setFormSent(true); })
            .catch(function (err) { return console.log(err); });
    };
    if (formSent) {
        return (_jsxs(Container, __assign({ className: "App", style: { textAlign: "center", fontFamily: Enums.FONT_PRIMARY } }, { children: [_jsx(ProgressBar, { percent: 100 }), _jsx(SuccessPage, {})] })));
    }
    // Shows when loading locale.
    if (!state) {
        return (_jsx(Box, __assign({ sx: { display: "flex" } }, { children: _jsx(CircularProgress, { color: "inherit" }) })));
    }
    return (_jsxs(Container, __assign({ className: "App", style: { textAlign: "center", fontFamily: Enums.FONT_PRIMARY } }, { children: [_jsx(ProgressBar, { percent: progressPercent }), last ? (_jsx(Container, __assign({ sx: {
                    px: 0,
                } }, { children: _jsx(ContactInfo, { id: contactID, color: textColor, handleBack: handleBack, handleSend: handleSend }) }))) : (_jsxs(Container, __assign({ className: "formContainer", sx: {
                    paddingRight: "8px!important",
                    paddingLeft: "8px!important",
                } }, { children: [_jsx(Header, { text: t(step.header) }), _jsx(Grid, __assign({ container: true, rowSpacing: 2, columnSpacing: 2, className: "formButtons" }, { children: step.type === Enums.ANSWERBUTTON &&
                            step.options.map(function (option) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsx(AnswerButtonGroup, { text: t(option.text), active: state[stepID] === t(option.text), onClick: function () {
                                        updateState(stepID, t(option.text));
                                        setTimeout(function () { return handleContinue(); }, 250);
                                    } }, option.id) }), option.id)); }) })), step.type === Enums.INPUT &&
                        step.options.map(function (input) { return (_jsx(InputField, { label: t(input.text), onChange: function (text) {
                                return updateState("".concat(stepID, " _ ").concat(input.text), text);
                            }, value: state["".concat(stepID, " _ ").concat(input.text)] }, input.id)); }), step.type === Enums.COMMENT && (_jsx(CommentField, { onChange: function (text) { return updateState(stepID, text); }, value: state[stepID], label: t(step.label) }, step.id))] }), step.id)), _jsxs(Grid, __assign({ container: true, columnSpacing: 2, justifyContent: "space-between", style: { marginTop: 20 } }, { children: [!last && i > 0 && (_jsx(Grid, __assign({ item: true, xs: 6, sm: 4 }, { children: _jsx(Button, __assign({ id: "backBtn", variant: Enums.DEFAULTVAR, size: Enums.BUTTONSIZE, onClick: function () { return handleBack(); }, style: CONTROL_BUTTON_STYLE }, { children: t("back") })) }))), !last && step.type !== Enums.ANSWERBUTTON && (_jsx(Grid, __assign({ item: true, xs: 6, sm: 4 }, { children: _jsx(Button, __assign({ id: "continueBtn", variant: Enums.DEFAULTVAR, size: Enums.BUTTONSIZE, onClick: function () { return handleContinue(); }, style: CONTROL_BUTTON_STYLE }, { children: t("continue") })) })))] }))] })));
}
export default Form;
