var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from "../Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RHFTextField from "../hook-form/RHFTextField";
import FormProvider from "../hook-form/FormProvider";
import { CONTACT_INFO_VALIDATION, CONTROL_BUTTON_STYLE, MARGIN_BOTTOM } from "../const";
import { Enums } from "../../../enums";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
export default function ContactInfo(_a) {
    var id = _a.id, handleBack = _a.handleBack, handleSend = _a.handleSend;
    var t = useTranslation().t;
    var resolver = CONTACT_INFO_VALIDATION();
    var methods = useForm({
        resolver: yupResolver(resolver),
        defaultValues: {
            name: "",
            phone: "",
            email: "",
            instagram: "",
        },
    });
    var handleSubmit = methods.handleSubmit;
    var onSubmit = function (infoData) {
        handleSend(infoData);
    };
    return (_jsxs(FormProvider, __assign({ methods: methods, id: id, onSubmit: handleSubmit(handleSend) }, { children: [_jsx(Header, { text: t("contactInfo") }), _jsx(RHFTextField, { name: Enums.NAME, label: t("name"), style: MARGIN_BOTTOM }), _jsx(RHFTextField, { placeholder: "+1 123 456 7890", name: Enums.PHONE, label: t("phone"), style: MARGIN_BOTTOM }), _jsx(RHFTextField, { name: Enums.EMAIL, label: t("email"), style: MARGIN_BOTTOM }), _jsx(RHFTextField, { name: Enums.INSTAGRAM, label: t("@instagram"), placeholder: t("optional"), style: MARGIN_BOTTOM }), _jsxs(Stack, __assign({ direction: "row", gap: 4, mt: 4 }, { children: [_jsx(Button, __assign({ id: "backBtn", variant: Enums.DEFAULTVAR, size: Enums.BUTTONSIZE, onClick: handleBack, style: CONTROL_BUTTON_STYLE }, { children: t("back") })), _jsx(Button, __assign({ id: "sendBtn", variant: Enums.DEFAULTVAR, size: Enums.BUTTONSIZE, type: "submit", form: Enums.C_FORM, onClick: handleSubmit(onSubmit), style: CONTROL_BUTTON_STYLE }, { children: t("send") }))] }))] })));
}
